@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"























































.hit-rate
  &__body
    padding: $size-m $size-s
    +flex-center-start

    +mq-xl__mf
      padding: $size-l
      +flex-column-center

  &__chart
    margin-right: $size-s

    +mq-m__mf
      margin-right: $size-l

    +mq-xl__mf
      margin-right: 0
      margin-bottom: $size-m

  &__text
    width: 220px
    max-width: 100%
    &__title
      margin-bottom: $size-xs
