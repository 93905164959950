@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"










































































































































































































































































































































.report
  background-color: $color-ice
  min-height: calc(100vh - #{$menu-height})
  padding-bottom: $end-space

  &.--center
    +flex-column-center

  &__body
    +mq-xl__mf
      display: flex

  &__hit-rate
    margin: $size-m 0
    align-items: center

    +mq-xl__mf
      margin-right: $size-m

  &__questions
    padding: 0 $size-m $size-m
    flex-grow: 1

    +mq-m__mf
      padding-left: 0
      padding-right: 0

    +mq-xl__mf
      margin-top: $size-m

    &__section
      margin-bottom: $size-l

    &__title
      margin-bottom: $size-m

.align-content-center
  width: 100%
  display: flex
  justify-content: center

.report__hit-rate__evaluation
  margin: $size-m 0
  display: none

  +mq-xl--mf
    display: flex

.report__body__evaluation
  +mq_xl--mf
    display: none
