@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"














































































































.drawer-navigator
  &__header
    padding: $size-s
      left: $size-l
    box-shadow: 0 1px 2px rgba(52, 60, 88, 0.2)
    +flex-space-between

    &__controls
      +flex-center

      &__previous
        padding:
          right: $size-xs

      &__next
        padding:
          left: $size-xs
          right: $size-xs

      &__close
        padding:
          left: $size-xs

      &__dropdown
        padding: 0 $size-xs

.--border-left
  border-left: 1px solid rgba($color-ink-lightest, 0.5)
