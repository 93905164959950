@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"






















































































































































.sas-button__text
  width: 100%
  text-align: left

.custom-dropdown
  display: inline-block
  position: relative
  width: max-content
  &:focus
    outline: 0

  &.--loading
    cursor: progress
    pointer-events: none

    .sas-button__icon
      opacity: 0

  &__spinner
    position: absolute
    right: 8px
    bottom: 6px
    z-index: 100
