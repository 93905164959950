@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"






















































.question-drawer-complaint
  padding: $size-m
  border-bottom: 1px solid $color-ink-lightest

  &__header
    margin-bottom: $size-s

    .sas-badge
      color: #794C00

  &__body
    &__complaint-filters
      margin-bottom: $size-m

      span:first-child
        display: block
        color: $color-ink-light
        margin-bottom: $size-xxs

      p
        font-weight: 500
