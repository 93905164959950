@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




























.custom-dropdown-item
  border-radius: 0 !important
  height: auto !important
  +space-inset(12px $size-s !important)
  justify-content: flex-start !important
  text-align: left

  &:not(:first-child)
    border-top: 1px solid rgba($color-ink, .15)
