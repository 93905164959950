=float-center
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

=proportional-video
  position: relative
  overflow: hidden
  padding-top: 56.25%

=proportional-video-iframe
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border: 0

=embedded-image
  width: auto !important
  max-width: 100% !important
  height: auto !important
  display: inline-block !important
  object:
    position: center center
    fit: contain

=embedded-paragraph
  line-height: 1.5 !important
  letter-spacing: 0.25px !important
  margin-block:
    start: 1em !important
    end: 1em !important
  word-break: break-word !important

=v-transition($name, $args: ())
  $default: ("dur-in": $speed-normal, "timing-in": ease-in-out, "delay-in": "", "dur-out": $speed-normal, "timing-out": ease-in-out, "delay-out": "")
  $params: map-merge($default, $args)

  .#{$name}
    &-enter-active
      transition: all #{map-get($params, "dur-in")} #{map-get($params, "timing-in")} #{map-get($params, "delay-in")}

    &-leave-active
      transition: all #{map-get($params, "dur-out")} #{map-get($params, "timing-out")} #{map-get($params, "delay-out")}

    &-enter,
    &-leave-to
      @content

=v-transition-in($name, $args: ())
  $default: ("dur": $speed-normal, "timing": ease-in-out, "delay": "")
  $params: map-merge($default, $args)

  .#{$name}
    &-enter-active
      transition: all #{map-get($params, "dur")} #{map-get($params, "timing")} #{map-get($params, "delay")}

    &-enter
      @content

=v-transition-out($name, $args: ())
  $default: ("dur": $speed-normal, "timing": ease-in-out, "delay": "")
  $params: map-merge($default, $args)

  .#{$name}
    &-leave-active
      transition: all #{map-get($params, "dur")} #{map-get($params, "timing")} #{map-get($params, "delay")}

    &-leave-to
      @content

=invisible-button
  background: 0
  border: 0
  cursor: pointer
