@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"















































































































































.question-drawer-details
  background: linear-gradient(180deg, rgba(0,0,0, 0.1) 0, rgba(0,0,0,0) 3px), linear-gradient(0deg, rgba(0,0,0, 0.15) 0, $color-ice 2px)
  padding: $size-m $size-l
  position: relative

  ::v-deep .sas-button.--active
    background: rgba($color-ink, .25)

  &__label
    color: $color-ink-light
    font-size: 11px
    text-transform: uppercase
    letter-spacing: 1.3px
    display: inline-block
    margin-bottom: $size-xxs

  &__visible
    +mq-m--mf
      +flex-center-start

    &__content
      margin-bottom: $size-s

      +mq-m--mf
        margin-right: $size-xxl

    &__level
      margin-bottom: $size-s

      +mq-m--mf
        flex-grow: 1

    &__toggle
      position: absolute
      top: $size-xs
      right: $size-xs

  &__collapsible
    overflow: hidden

    +mq-m--mf
      padding-top: $size-l

    &__object
      margin-bottom: $size-s
      +mq-m--mf
        margin-bottom: $size-l
