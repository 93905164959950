@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"

















































.problem-picker
  &__item
    border-radius: 10px
    cursor: pointer
    color: $color-ink-light
    flex-shrink: 0
    font-size: $font-size-s
    font-weight: $font-weight-semi-bold
    +flex-center
    +space-inline($size-xs)
    +transition($speed-x-fast)

    &:focus
      outline: none
      box-shadow: inset 0 0 0 3px $color-primary-light

    &.--active
      background: rgba(192, 217, 246, 0.5)
      color: $color-ink

    &.--disabled
      cursor: not-allowed
      opacity: 0.5

      &:focus
        box-shadow: none
