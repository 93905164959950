@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




















































































.question-drawer-body
  overflow-x: auto
  padding-bottom: $end-space

  &__header
    padding: $size-l $size-l 0
    margin-bottom: $size-m
    +flex-space-between

    &__skill
      ::v-deep .sas-badge.sas-badge--neutral
        background: $color-white
        border: 1px solid $color-ink-lighter

  &__statement,
  &__explanation
    ::v-deep img
      +embedded-image

    ::v-deep p
      +embedded-paragraph

  &__statement
    padding: 0 $size-l
    margin-bottom: $size-m

  &__options
    padding: 0 $size-l
    margin-bottom: $size-xl

    &__item
      margin-bottom: $size-s
      +flex-center-start

      &__key
        margin-right: $size-s

        ::v-deep .sas-badge
          border: 1px solid transparent

          &.sas-badge--neutral
            background: $color-white
            border-color: $color-ink-lighter

  &__explanation
    border-top: 1px solid $color-ink-lightest
    padding: $size-l
    width: fit-content
    min-width: 100%

    &__title
      margin-bottom: $size-s

    &__video
      +proportional-video

      ::v-deep iframe
        +proportional-video-iframe
