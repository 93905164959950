@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"





























































.questions
  &__item
    +flex-center
    +space-stack($size-s)
    padding: $size-s $size-s !important

    &__text
      flex-grow: 1

    &__number
      display: block
      margin-right: $size-s
      flex-shrink: 0
      +flex-center

    &__statement
      margin-bottom: $size-xxs

    &__level
      margin-left: $size-xxl
      margin-right: $size-xxl
      width: $size-xxl
      flex-shrink: 0

    &__hit
      margin-left: $size-s
      flex-shrink: 0
