@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"
















































































.report-header
  padding: $size-m 0
  background-color: #e0e4ea
  color: $color-white
  +transition($speed-fast)
  min-height: 181px

  +mq-s--mf
    min-height: 198px

  .slide-fade
    &-enter-active
      transition: all .3s .2s ease

    &-enter
      transform: translateX(10px)
      opacity: 0

  .up-fade
    &-enter-active
      transition: all .3s .4s ease

    &-enter
      transform: translateY(10px)
      opacity: 0

  .sas-button
    &.--tertiary
      color: $color-white

  &__subtitle
    color: transparentize($color-white, 0.2)
    margin-top: $size-l
