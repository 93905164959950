@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"
















































.question-problem-item
  padding: $size-m 0

  &__author
    margin-bottom: $size-s

  &__body
    &__reasons
      margin-bottom: $size-m

      span
        display: block
        font-size: $font-size-xs
        color: #595b5f
        margin-bottom: $size-xxs

      p
        font-weight: 500
