@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"













































































































































.question-drawer-body
  padding-bottom: $end-space

  &__header
    padding: $size-l $size-l 0
    margin-bottom: $size-m
    +flex-space-between

  &__statement
    padding: 0 $size-l
    margin-bottom: $size-m

    &__line
      margin-bottom: $size-xs

  &__options
    padding: 0 $size-l
    margin-bottom: $size-xl

    &__item
      margin-bottom: $size-s
      +flex-center-start

      &__key
        margin-right: $size-s

  &__explanation
    padding: $size-l
    border-top: 1px solid $color-ink-lightest

    &__title
      margin-bottom: $size-s

    &__line
      margin-bottom: $size-xs
