@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




























































































































.questions-card
  +space-stack($size-s)
  cursor: pointer

  &__wrapper
    +flex-center
    padding: $size-s
    border-radius: $size-xs
    +transition(200ms)

    .--warning &
      box-shadow: 0 0 0 2px #794C00

    .--active &
      box-shadow: inset 0 0 0 2px $color-primary
      background-color: $color-primary-lightest

  &__text
    flex-grow: 1

  &__number
    background: transparentize($color-ink-lightest, 0.5)
    border-radius: $border-radius-m
    display: block
    height: $size-m
    width: $size-m
    margin-right: $size-s
    flex-shrink: 0
    +flex-center

    span
      font-weight: 900
      color: $color-ink-light

    .--active &
      background-color: $color-primary

      span
        color: $color-white

    .--warning &
      background-color: $color-warning-lightest

      span
        color: $color-warning-darkest

  &__statement
    margin-bottom: $size-xxs

  &__content
    color: $color-ink-light

  &__hit
    margin-left: $size-s
    flex-shrink: 0
