@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"





































































































































































































































































































.question-drawer
  ::v-deep .drawer
    overflow: visible
    overflow-y: auto

  &__header
    +flex-center-start
    width: 100%

    .sas-badge
      margin-left: $size-s

    &__hit
      display: none
      margin-left: $size-xs
      font-size: $font-size-s

      +mq-s--mf
        display: inline

      &.--success
        color: $color-success-dark

      &.--danger
        color: $color-danger-dark
