@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"






















































































































































































.question-drawer-problems
  &__header
    +flex-center
    +flex-space-between
    width: 100%
    padding-right: $size-xs

  &__body
    ::v-deep .problem-picker
      margin: $size-m $size-m 0

    &__loading
      margin-top: 30vh
      +flex-center

    &__filter-title
      margin: $size-m $size-m 0

    &__item
      padding: $size-m $size-m 0

      &:not(:last-child)
        border-bottom: 1px solid #C9CCCF

    &__filter-title
      margin-top: $size-l
